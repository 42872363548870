import React, { useState, useMemo } from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import Hero from '../../components/hub/hero/hero'
import Container from '../../components/container'
import Card from '../../components/hub/card/card'
import CardSlider from '../../components/hub/cardSlider/cardSlider'
import SubscribeBar from '../../components/subscribeBar/subscribeBar'
// import FacetBar from '../../components/hub/facetBar/facetBar'

import * as styles from '../../components/globals-v2.module.css'

export const query = graphql`
  query ContentHubQuery {
    page: sanityPage(title: { eq: "Content Hub" }) {
        title
        metaTitle
        metaDescription
        metaKeywords
        metaImage {
          asset {
            _id
            url
          }
        }
    }

    feature: sanityVideo(featured: {eq: true}) {
        _type
        featureImage {
          asset {
            _id
            url
          }
        }
        series {
            title
        }
        title
        videoId
        slug {
          current
        }
    }

    latestWritten: sanityWritten(featured: {eq: true}) {
      _type
      tags {
          title
          slug {
              current
          }
      }
      openLink
      title
      blurb
      slug {
          current
      }
      featureImage {
          asset {
              url
              _id
          }
      }
    }

    written: allSanityWritten(limit: 10, sort: {order: DESC, fields: publishedAt}, filter: {featured: {in: [false, null]}}) {
        nodes {
            _type
            tags {
                title
                slug {
                    current
                }
            }
            title
            blurb
            slug {
                current
            }
            featureImage {
                asset {
                    url
                    _id
                }
            }
        }
    }

    audio: allSanityAudio(limit: 10, sort: {order: DESC, fields: publishedAt}) {
        nodes {
            _type
            tags {
              title
              slug {
                current
              }
            }
            title
            slug {
              current
            }
            featureImage {
              asset {
                _id
                url
              }
            }
            appleUrl
            spotifyUrl
            youTubeUrl
        }
    }

    video: allSanityVideo(limit: 10, sort: {order: DESC, fields: publishedAt}, filter: {featured: {in: [false, null]}}) {
        nodes {
            _type
            tags {
              title
              slug {
                current
              }
            }
            title
            slug {
              current
            }
            series {
              title
            }
            featureImage {
              asset {
                _id
                url
              }
            }
            hideText
        }
    }

    social: allSanitySocial(limit: 10, sort: {order: DESC, fields: publishedAt}) {
      nodes {
        url
        title
        platform
        featureImage: image {
          asset {
            _id
            url
          }
        }
      }
    }
  }
`

const ContentHub = props => {
  const { data, errors } = props

  const [feature, setFeature] = useState(null)
  const [writtenArticles, setWrittenArticles] = useState(null)
  const [latestWritten, setLatestWritten] = useState(null)
  const [audioPosts, setAudioPosts] = useState(null)
  const [videoPosts, setVideoPosts] = useState(null)
  const [socialPosts, setSocialPosts] = useState(null)

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page

  useMemo(() => {
    if (data && feature === null) {
      setFeature(data.feature)
    }
    if (data && latestWritten === null) {
      setLatestWritten(data.latestWritten)
    }
    if (data && writtenArticles === null) {
      setWrittenArticles(data.written.nodes)
    }
    if (data && audioPosts === null) {
      setAudioPosts(data.audio.nodes)
    }
    if (data && videoPosts === null) {
      setVideoPosts(data.video.nodes)
    }
    if (data && socialPosts === null) {
      setSocialPosts(data.social.nodes)
    }
  }, [])

  // console.log("Written", writtenArticles);
  // console.log("Latest Written", latestWritten);
  // console.log("Audio", audioPosts);
  // console.log("Video", videoPosts);

  if (!page) {
    throw new Error(
      'Missing "Content Hub" page data. Open the studio at http://localhost:3333 and add "Content Hub" page data and restart the development server.'
    )
  }

  return (
    <Layout hideContactBar hideAnnouncements logoOverrideUrl='/start/' darkMode version='2'>
      <SEO title={page.metaTitle || page.title} description={page.metaDescription} keywords={page.metaKeywords.split(',')} imageFB={page.metaImage} />

      <Hero type='main' record={feature} />

      <div className={styles.backgroundControl}>
        <Container size='large'>
          {/* <section className={`facetBar`}>
                    <FacetBar />
                </section> */}

          <section className='writtenArticles'>
            <h6 style={{ margin: '0 0 60px' }}>
              <a href='/start/written/'>Written</a>
            </h6>
            {latestWritten && (
              <Card type='detail' data={latestWritten} />
            )}
            <CardSlider type='simple' data={writtenArticles} settings={{ slidesToShow: 3 }} alternate />
          </section>
        </Container>
      </div>

      <div className={styles.backgroundControl}>
        <Container size='large' class={styles.sectionPad}>
          <section className={styles.audioPosts}>
            <h6><a href='/start/audio/'>Audio</a></h6>
            <CardSlider type='simple' data={audioPosts} settings={{ slidesToShow: 3 }} />
          </section>

          <section className='videos'>
            <h6 style={{ margin: '0 0 60px' }}><a href='/start/video/'>Videos</a></h6>
            <CardSlider type='visual' data={videoPosts} />
          </section>
        </Container>
      </div>

      <div className={styles.backgroundControlAlt}>
        <Container size='large'>
          <SubscribeBar />
        </Container>
      </div>

      <div className={styles.backgroundControl}>
        <Container size='large' class={styles.sectionPad}>
          <section className='socialPosts'>
            <h6 style={{ margin: '0 0 60px', textAlign: 'center' }}>Social</h6>
            <CardSlider
              type='social' data={socialPosts} settings={{
                slidesToShow: 5,
                arrows: false,
                responsive: [
                  {
                    breakpoint: 1440,
                    settings: {
                      slidesToShow: 4
                    }
                  },
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 3
                    }
                  },
                  {
                    breakpoint: 680,
                    settings: {
                      slidesToShow: 2
                    }
                  },
                  {
                    breakpoint: 440,
                    settings: {
                      slidesToShow: 1
                    }
                  }
                ]
              }} alternate
            />
          </section>
        </Container>
      </div>

    </Layout>
  )
}

export default ContentHub
